import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database'; // Import other services as needed
import { getAuth } from 'firebase/auth'; // For authentication, if used

const firebaseConfig = {

  apiKey: "AIzaSyA5kATQd9EIEWpKiLuTHwlduZUchfPEYSg",
  authDomain: "otp-genrate-79bd5.firebaseapp.com",
  databaseURL: "https://otp-genrate-79bd5-default-rtdb.firebaseio.com",
  projectId: "otp-genrate-79bd5",
  storageBucket: "otp-genrate-79bd5.firebasestorage.app",
  messagingSenderId: "92033318391",
  appId: "1:92033318391:web:009e96eba8cfea9c2242f2",
  measurementId: "G-V5K3BKCWMW"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize other services if needed
const database = getDatabase(app); // For Realtime Database
const auth = getAuth(app); // For Firebase Authentication

export { app, database, auth }; // Export as needed