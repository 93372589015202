import React from 'react';
import {HashRouter, Routes, Route, Router, Navigate} from 'react-router-dom';
import Dashboard from './Dashboard';
import GeneralSetting from './GeneralSetting';
import ProductView from './ProductView';
import Com from './Com';
import ProductAdd from './ProductAdd';
import Complains from './Complains';
import Requests from './Requests';

import AddSupplements from './AddSupplements';
import ViewProducts from './ViewProducts';
import PushNotif from './PushNotif';
import AddStore from './AddStore';

import AddRestaurant from './AddRestaurant';


import StoreList from './StoreList';
import Vehicle from './Vehicle';
import AddVehicle from './AddVehicle';
import Table from './Table';
import Login from './Login';
import RejectedRes from './RejectedRes';
import BlockedRes from './BlockedRes';
import PendingRes from './PendingRes';
import OrderTable from './OrderTable';
import Cuisine from './Cuisine';
import Documents from './Documents';
import BlockedCourier from './BlockedCourier';
import PendingCourier from './PendingCourier';
import RejectedCourier from './RejectedCourier';
import StoreRating from './StoreRating';
import MenuTable from './MenuTable';
import ResturantRating from './ResturantRating';
import CourierRating from './CourierRating';
import DocRider from './DocRider';
import ResturantTable from './ResturantTable';
import ViewResturants from './ViewResturants';
import AddCuisine from './AddCuisine';
import Invoice from './Invoice';
import NextSub from './NextSub';
import SubCat from './SubCat';
import MainCat from './MainCat';
import InvoicePrint from './InvoicePrint';
import Profile from './Profile';
import Contact from './Contact';
import Orders from './Orders';
import Reset from './Reset';

import RiderOrders from './RiderOrders';
import FreeDelivery from './FreeDelivery';
import SharingGift from './SharingGift';

import DeliveryCharges from './DeliveryCharges';

import Menu from './Menu';
import AddResturant from './AddResturant';

import AddMenu from './AddMenu';
import ViewRiders from './ViewRiders';
import AddRider from './AddRider';
import OrderMenu from './OrderMenu';
import UpdateOrders from './UpdateOrders';
import Riders from './Riders';
import AddUser from './AddUser';
import AddPromos from './AddPromos';
import ViewPromo from './ViewPromo';
import ViewUser from './ViewUser';
import Footer from './Footer';
import Form from './Form';
import Earning from './Earning';
import ResOrders from './ResOrders';
import ResturantSignUp from './ResturantSignUp';

import StoreLogin from './StoreLogin';

import history from "./history";

import NotFound from "./Errors";


import mainvariations from "./MainVariations";





export const myContext = React.createContext();
class  Content extends React.Component
{
   constructor(props) {
      super(props)
   
    
    
     this.state={
      token:'',
      email:"",
    };

  
   }

    
   
   render()
   {

  
 return (
  //  <Router basename={"/secure/cwp_4b91"} history={history}>
  <Routes>
           {/* <Route
         path="/aboutus"
         element={<() => {
           window.location.href = "https://circularbyte.com";
           return null;
         }}
       /> */}


       {/* <Route exact path="/" element={<Login/>} /> */}
       {/* <Route exact path="/404error" element={<error404/>} /> */}
      
       <Route path="/" element={<Login />} />
       <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/404error" element={<NotFound />} />

       <Route path="/storelogin" element={<StoreLogin />} />

       <Route path="/viewuser" element={<ViewUser />} />

       <Route path="/deliverycharges" element={<DeliveryCharges />} />


       <Route path="/mainvariations" element={<mainvariations />} />

       <Route path="/vehicle" element={<Vehicle />} />

       <Route path="/productadd" element={<ProductAdd />} />
       <Route path="/addstore" element={<AddStore />} />

       <Route path="/addrestaurant" element={<AddRestaurant />} />

       <Route path="/ordertable" element={<OrderTable />} />

       <Route path="/menu" element={<Menu />} />

       {/* <Route path="/cuisine" element={<Cuisine/>} /> */}
      
       <Route path="/resorders" element={<ResOrders/>} />
       <Route path="/addmenu" element={<AddMenu/>} />
       <Route path="/docrider" element={<DocRider/>} />
       <Route path="/resturantsignup" element={<ResturantSignUp/>} />
       <Route path="/ordermenu" element={<OrderMenu/>} />
       <Route path="/updateorders" element={<UpdateOrders/>} />

       <Route path="/addpromos" element={<AddPromos/>} />
       <Route path="/viewpromo" element={<ViewPromo/>} />
       <Route path="/invoiceprint" element={<InvoicePrint/>} />
       <Route path="/footer" element={<Footer/>} />
       <Route path="/generalsetting" element={<GeneralSetting/>} />
       <Route path="/com" element={<Com/>} />
       <Route path="/push" element={<PushNotif/>} />
       <Route path="/earn" element={<Earning/>} />
       <Route path="/documents" element={<Documents/>} />
       <Route path="/resturanttable" element={<ResturantTable/>} />

       <Route path="/storelist" element={<StoreList/>} />
       <Route path="/addsupplements" element={<AddSupplements/>} />
       <Route path="/complains" element={<Complains/>} />

       <Route path="/requests" element={<Requests/>} />

       

       {/* <Route path="/addcuisine" element={<AddCuisine/>} /> */}
       {/* <Route path="/addvehicle" element={<AddVehicle/>} /> */}

       <Route path="/viewproducts" element={<ViewProducts/>} />
       <Route path="/productview" element={<ProductView/>} />

       {/* <Route path="/free" element={<FreeDelivery/>} /> */}
       <Route path="/share" element={<SharingGift/>} />
       {/* <Route path="/riderorders" element={<RiderOrders/>} /> */}
       <Route path="/menutable" element={<MenuTable/>} />

       {/* <Route path="/table" element={<Table/>} /> */}
       { <Route path="/storerating" element={<StoreRating/>} /> }
       <Route path="/ratingtable" element={<ResturantRating/>} />
       { <Route path="/courierrating" element={<CourierRating/>} /> }
       {/* <Route path="/rejectedres" element={<RejectedRes/>} />
       <Route path="/pendingres" element={<PendingRes/>} /> */}
       {/* <Route path="/blockedres" element={<BlockedRes/>} /> */}
       <Route path="/reset" element={<Reset/>} />

       {/* <Route path="/blockedcourier" element={<BlockedCourier/>} />
       <Route path="/pendingcourier" element={<PendingCourier/>} />
       <Route path="/rejectedcourier" element={<RejectedCourier/>} /> */}

       <Route path="/nextsub" element={<NextSub/>} />
       <Route path="/subcat" element={<SubCat/>} />
       <Route path="/maincat" element={<MainCat/>} />

       <Route path="/viewresturants" element={<ViewResturants/>} />
       <Route path="/addrider" element={<AddRider/>} />
       <Route path="/riders" element={<Riders/>} />
       <Route path="/addresturant" element={<AddResturant/>} />

       <Route path="/profile" element={<Profile/>} />
       <Route path="/orders" element={<Orders/>} />

       <Route path="/invoice" element={<Invoice/>} />

       <Route path="/viewriders" element={<ViewRiders/>} />
       <Route path="/adduser" element={<AddUser/>} />
    
      
   </Routes>
 );
}
}
export default Content